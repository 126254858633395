<template>
  <section>
    <b-row class="header justify-content-between">
      <b-row>
        <b-col cols="2">
          <router-link
            to="/faturamento-tiss/materiais-e-medicamentos"
            tag="span"
            class="path"
          >
            <ArrowLeft class="icon"/>
          </router-link>
        </b-col>
        <b-col cols="10">
          <Ellipsis>
            <h3>Tabela {{ parseTableType(type) }}</h3>
          </Ellipsis>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        @click="emitUpdate"
      >
        Atualizar tabela
      </b-button>
    </b-row>
    <b-row class="justify-content-between mt-3">
      <b-col>
        <b-row>
          <b-col >
            <strong>Tabela</strong>
            <Ellipsis>Tabela {{ parseTableType(type) }}</Ellipsis>
          </b-col>
          <b-col>
            <strong>Data vigência inicial</strong>
            <p>{{ effectiveDate ?? 'DD/MM/YYYY' }}</p>
          </b-col>
          <b-col>
            <strong>Atualização</strong>
            <p>{{ lastUpdated ?? 'DD/MM/YYYY '}}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="text-right" align-self="center">
        <toggle-button
          class="autoFillBtn"
          :value="isSimplifiedTable"
          @change="updateSimplifiedTable"
          :sync="true"
          :height="24"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
        />
        Visualização simplificada da tabela
      </b-col>
    </b-row>
  </section>
</template>

<script>
import api from '@/modules/tiss/matMed/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { parseDate } from '@/utils/dateHelper'

export default {
  name: 'pageHeader',
  components: {
    Ellipsis: () => import('@/components/General/Ellipsis.vue'),
    ArrowLeft: () => import('@/assets/icons/arrow-left.svg'),
  },
  props: {
    type: String,
    loading: Boolean,
    simplifiedTable: Boolean,
  },
  data() {
    return {
      effectiveDate: null,
      lastUpdated: null,
      clinic: getCurrentClinic(),
      isSimplifiedTable: this.simplifiedTable,
    }
  },
  async created() {
    try {
      const { data } = await api.getImportedMaterialMedicines(this.clinic.id)
      const result = data.find(table => table.table_type === this.type)

      this.effectiveDate = parseDate(result.effective_date)
      this.lastUpdated = parseDate(result.updated_at)
    } catch (error) {
      this.$toast.error('Não foi possível encontrar a última atualização da tabela.')
    }
  },
  methods: {
    emitUpdate() {
      this.$emit('update')
    },
    updateSimplifiedTable(input) {
      const { value } = input
      this.isSimplifiedTable = value
      this.$emit('updateSimplifiedTable', value)
    },
    parseTableType(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin: 20px 0;

  h3 {
    color: var(--dark-blue, #0C1D59);
    font-size: 18px;
    font-family: Red Hat Display;
    font-weight: 700;
    line-height: 134%;
    letter-spacing: -0.18px;
  }
}

strong {
  color: var(--dark-blue, #0C1D59);
  font-size: 13px;
  font-family: Nunito Sans;
  font-weight: 700;
  line-height: 130%;
}
</style>